jQuery(document).ready(function ($) {
  //Menu toggles
  $(".main-header__hamburger").on("click", function () {
    $(this).toggleClass("main-header__hamburger--active");
    $(".main-header__menu").toggleClass("main-header__menu--active");
  });

  // Reviews modal toggle
  $(".reviews__toggle").on("click", function () {
    $(".reviews__toggle--active")
      .not($(this))
      .toggleClass("reviews__toggle--active");
    $(this).toggleClass("reviews__toggle--active");
    $(".reviews__toggle", this).toggleClass("reviews__toggle--active");
  });

  $(".reviews__cross").on("click", function () {
    $(".reviews__toggle--active").toggleClass("reviews__toggle--active");
  });

  //  Flex-footer-sm toggle
  $(".flex-footer__toggle").on("click", function () {
    $(".flex-footer__toggle--active")
      .not($(this))
      .toggleClass("flex-footer__toggle--active");
    $(this).toggleClass("flex-footer__toggle--active");
    $(".flex-footer__toggle", this).toggleClass("flex-footer__toggle--active");
  });

  $(".flex-footer__cross").on("click", function () {
    $(".flex-footer__toggle--active").toggleClass(
      "flex-footer__toggle--active"
    );
  });

  //  Flex-footer-lg toggle
  $(".flex-footer-lg__toggle").on("click", function () {
    $(".flex-footer-lg__toggle--active")
      .not($(this))
      .toggleClass("flex-footer-lg__toggle--active");
    $(this).toggleClass("flex-footer-lg__toggle--active");
    $(".flex-footer-lg__toggle", this).toggleClass(
      "flex-footer-lg__toggle--active"
    );
  });

  $(".flex-footer-lg__cross").on("click", function () {
    $(".flex-footer-lg__toggle--active").toggleClass(
      "flex-footer-lg__toggle--active"
    );
  });
});
