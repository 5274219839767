import Swiper from "../../../node_modules/swiper/swiper-bundle";

// let mySwiper = new Swiper(".class", {});

let flexFooterSwiper = new Swiper(".flex-footer__slider", {
  slidesPerView: "1",
  spaceBetween: 40,
  direction: "horizontal",

  navigation: {
    nextEl: ".flex-footer__next",
    prevEl: ".flex-footer__prev",
  },
  //   breakpoints: {
  //     "@0.00": {
  //       slidesPerView: 1,
  //     },
  //     "@0.60": {
  //       slidesPerView: 2,
  //     },
  //   },
});

flexFooterSwiper;
